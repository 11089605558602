@media screen and (max-width: 576px)  {
  .mobile-break { display: none; }
}

body {
  font-family: "yorkten-slab-normal", serif;
  font-weight: 200;
}

h1, h2, h3, .card-body>.card-title {
  font-family: "commuters-sans", sans-serif;
  font-weight: 900;
}

.site-header {
  height: 10px;
  background: rgb(98,4,4);
  background: linear-gradient(144deg, rgba(98,4,4,1) 0%, rgba(121,69,9,1) 35%, rgba(0,255,239,1) 100%);
}

button {
  font-family: "commuters-sans", sans-serif;
  font-weight: 900 !important;
}

.list-inline-item {
  font-weight: 500;
}

.nav-link {
  font-family: "commuters-sans", sans-serif;
  font-weight: 400;
}

.feature {
  font-family: "commuters-sans", sans-serif;
  font-weight: 700;
}

.modal-fullscreen {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
}

.modal-fullscreen .modal-content {
  border: none;
  border-radius: 0;
  overflow: auto;
}